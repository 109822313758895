const fetch = require("isomorphic-fetch")
// From http://stackoverflow.com/a/15724300
/** Gets the named cookie from the cookie jar. */
export function getCookie(name) {
  var value = " " + document.cookie
  var parts = value.split(" " + name + "=")
  if (parts.length == 2) return parts.pop().split(";").shift()
}
/** Proxies fetch(), adding Fleet API authentication headers. */
export function apiFetch(url, config) {
  var myConfig = Object.create(config || Object)
  myConfig.headers = Object.create(myConfig.headers || Object)
  myConfig.headers["Accept"] = "application/json"
  myConfig.headers["Content-Type"] = "application/json"
  myConfig.headers["X-CSRFToken"] = getCookie("csrftoken")
  myConfig.credentials = "same-origin"
  return fetch(url, myConfig)
}
export function apiResponseCheck(response) {
  if (response.ok) {
    return response.json()
  }
  return Promise.reject(response.statusText)
}
