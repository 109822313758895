var $ = require("jquery")
var bootstrap = require("bootstrap")

// For the benefit of scripts that haven't been webpackified yet
window.jQuery = window.$ = $

// Polyfill NodeList.forEach
if (typeof NodeList.prototype.forEach === "undefined") {
  NodeList.prototype.forEach = Array.prototype.forEach
}

// Include Sass
require("../fleet/global-sass/main.scss")

// Load Stickyfill
require("Stickyfill")

import "./gql"

$(function () {
  // Set up Stickyfill
  $(".navbar").Stickyfill()

  // Note: This can't be extracted out into a function because then
  // Webpack can't statically analyse it
  if ($("#quicksearch-container").length) {
    require.ensure(["./quicksearch"], function (require) {
      require("./quicksearch").init()
    })
  }
  if (
    $("select")
      .not(".noselect2, .quicksearch")
      .add("input.date, input.time, input.datetime").length
  ) {
    require.ensure(["./forms"], function (require) {
      require("./forms").init()
    })
  }
  if ($(".report").length) {
    require.ensure(["./reporting/index"], function (require) {
      require("./reporting/index").init()
    })
  }
  if ($("#report-data").length) {
    require.ensure(["./reporting/table"], function (require) {
      require("./reporting/table").init()
    })
  }
  if ($("#chart").length) {
    require.ensure(["./reporting/chart"], function (require) {
      require("./reporting/chart").init()
    })
  }
  if ($("#escalation-app-root").length) {
    require.ensure(["./escalation/escalation.jsx"], function (require) {
      require("./escalation/escalation.jsx").default()
    })
  }

  if ($(".odometer-list").length) {
    require.ensure(["./odometers/index.js"], function (require) {
      require("./odometers/index.js").init()
    })
  }

  if ($(".distribution-picker").length) {
    require.ensure(
      ["react", "react-dom", "./scheduling/distributionPicker.jsx"],
      function (require) {
        var DistributionPicker = require("./scheduling/distributionPicker.jsx")
          .default
        var react = require("react")
        var reactDom = require("react-dom")
        $(".distribution-picker").each(function () {
          reactDom.render(
            react.createElement(DistributionPicker, {
              name: this.getAttribute("data-name"),
              initialValue: parseInt(this.getAttribute("data-value"), 10),
              maxValue: parseInt(this.getAttribute("data-max-value"), 10),
              type: this.getAttribute("data-type"),
            }),
            this
          )
        })
      }
    )
  }

  if ($("#inspection-diagrams").length) {
    require.ensure(["./inspections/diagram/index"], function (require) {
      require("./inspections/diagram/index")
    })
  }

  if ($("select.fuzzy-match").length) {
    require.ensure(["./units/fuzzy-select"], function (require) {
      require("./units/fuzzy-select").init()
    })
  }

  document.querySelectorAll(".js-mount-point").forEach((node) => {
    console.log(node.dataset.jsPath)
    const kwargs = JSON.parse(node.dataset.kwargs)
    require.ensure([], (require) => {
      require(`./${node.dataset.jsPath}/mount`).default(node, kwargs)
    })
  })
})
